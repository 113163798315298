import http from '@/utils/http';

/**
 * 轮播图
 */
export default {
  getList: {
    p: 'post,/manage/banner/getlist',
    r: (data) => http({ url: '/manage/banner/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/banner/add',
    r: (data) => http({ url: '/manage/banner/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/banner/update',
    r: (data) => http({ url: '/manage/banner/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/banner/delete',
    r: (data) => http({ url: '/manage/banner/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/banner/getOne',
    r: (data) => http({ url: '/manage/banner/getOne', method: 'post', data })
  },
  exportFile: {
    p: 'post,/system/export/user',
    r: (data) => http({
      url: '/system/export/user', method: 'post', responseType: 'blob', data
    })
  }
};
